<template>
  <div class="appHead">
    <div class="box clearfix center">
      <div class="left">
        <router-link to="/home/main">
          <i class="icon" :class="{ scrolled: scrolledDown || onCharaterPage, active: isActive, }"> <img style="width: 100%" :src="logo" /></i>
        </router-link>
      </div>
      <div v-if="!isDev" class="right">
        <!-- Show door when not logined yet, otherwise showing member icon -->

        <div class="nav_title">
          <router-link to="/home/search">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass"
          /></router-link>
        </div>
        <div class="nav_title">
          <router-link to="/login">
            <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
          </router-link>
        </div>
        <div class="nav_title">
          <router-link to="/home/mygoodsList">
            <font-awesome-icon icon="fa-solid fa-cart-shopping" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="navWrapper">
       <div id="menu" :class="{ active: isActive, scrolled: scrolledDown }">
        <el-menu
          class="el-navbar"
          mode="horizontal"
          background-color="transparent"
          text-color="#fff"
          :router="true"
        >
          <!-- :default-active="activeIndex" -->
          <!-- @select="handleSelect" -->
          <el-menu-item index="/home/main" route="/home/main" class="logo-item" @click="select()"><img style="width: 100%" :src="logo" /></el-menu-item>
          <el-menu-item index="/home/main" route="/home/main" class="first-item" @click="select()">{{ $t("common.home") }}</el-menu-item>
          <el-menu-item index="/home/character" route="/home/character" @click="select()">{{ $t("common.character") }}</el-menu-item>
          <!-- <el-menu-item :disabled="isDev" index="/home/socialmedia" route="/home/socialmedia" @click="select()">{{ $t("common.socailMedia") + (isDev ? $t("common.inDev") : '') }}</el-menu-item> -->
          <el-menu-item class="last-item">
            <Transition>
            <el-select v-model="$i18n.locale" class="lang-selector" v-if="!scrolledDown">
              <el-option
                v-for="item in languages"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </Transition>
            </el-menu-item>
        </el-menu>
        <div id="toggle" @click="select()">
          <div class="span" id="top" :class="{ active: isActive }"></div>
          <div class="span" id="middle" :class="{ active: isActive }"></div>
          <div class="span" id="bottom" :class="{ active: isActive }"></div>
        </div>
       </div>
    </div>
  </div>
</template>
      
<script>
// @ is an alias to /src

import logo from "../assets/img/logo_white.svg";
export default {
  name: "home",
  components: {},
  data() {
    return {
      isDev: true,
      languages: [
        {
          value: "en",
          label: "English",
        },
        {
          value: "zh",
          label: "繁體中文",
        },
      ],
      logo: logo,
      isActive: false,
      scrolledDown: false,
      onCharaterPage: false
    }
  },
  created() {
    const me = this
    if(this.$store.state.isTaiwan) this.$i18n.locale = 'zh'
    window.addEventListener("resize", this.resizeEvent);
    window.addEventListener('scroll', this.handleScroll);
    this.onCharaterPage =  this.$router.currentRoute.path === '/home/character'
  },
  computed: {
    },
  mounted() {
    this.resizeEvent()
  },
  unmounted () {
    window.removeEventListener('resize', this.resizeEvent);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    select() {
      this.isActive = !this.isActive;
      this.resizeEvent()
      this.handleScroll()
      // close the menu when select
    },
    resizeEvent(e) {
      const currentHeight = screen.height
      const currentWidth = screen.width
      const target = window.document.getElementById('menu')
      if (currentWidth <= 600 && this.isActive) {
        target.style.height = currentHeight + 'px'
      } else {
        target.style.height = 'unset'
      }
    },
    handleScroll(e) {
      const scrollY = window.scrollY
      if (scrollY >= 30 && !this.isActive) {
        this.scrolledDown = true
      } else {
        this.scrolledDown = false
      }
    }
  },
  watch:{
    $route (to, from){
        this.onCharaterPage =  this.$router.currentRoute.path === '/home/character'
        scroll(0,0)
    }
} 
};
</script>
<style lang="scss" rel='stylesheet/scss'>
.appHead {
  z-index: 100;
  // TODO: set color changed when moved out the range of main image.
  position: fixed;
  color: white;
  font-size: 20px;
  height: 80px;
  // background-color: rgba(20, 20, 20, 0.5);
  width: 100%;
  .box {
    // height: 80px;
    // height: 30px;
    margin: 0 auto;
    .left {
      float: left;
      // line-height: 30px;
      @media (max-width: 600px) {
        margin-top: 10px;
        margin-left: 10px;
      }
      margin-top: 25px;
      margin-left: 30px;
      .icon {
        display: inline-block;
        position: absolute;
        z-index: 1;
        @media (max-width: 600px) {
          width: 52px;
        }
        width: 120px;
        // height: 30px;
        font-size: 48px;
        // float: left;
        // background-image: url('/img/美的图标.png');
        background-repeat: no-repeat;
        background-size: 200px 80px;
        background-position: -80px -6px;
        transition:all 500ms ease;
      }
      .icon.scrolled {
        @media (max-width: 600px) {
          width: 52px;
        }
        width: 70px;
      }
      .icon.active {
        z-index: 0;
        transition:all 0ms ease;
      }
    }
    .right {
      float: right;
      margin-right: 30px;
      margin-top: 30px;
      .nav_title {
        margin-left: 20px;
        display: inline-block;
        vertical-align: super;
      }
      .login {
        cursor: pointer;
      }
    }
    span {
      /*background-size:20px;*/
      float: left;
      display: block;
      margin-left: 20px;
      // line-height: 30px;
      cursor: pointer;
    }
    span:hover {
      color: darkred;
    }
  }
  .lang-selector {
    width: 115px;
    .el-input__inner {
      background: #080c27a9;
      color: white;
      // height: 250px;
      border: 2px solid;
      border-image: linear-gradient(85.56deg, #225CC7 7.75%, #BA49B5 91.09%);
      border-image-slice: 1;
      // padding-top:50px;
      border-radius: 5px;
      font-size: 16px;
    }
  }
}
</style>
<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
  .el-navbar {
    border-bottom: unset !important;
    .el-menu-item {
      font-size: 20px;
      border-bottom: unset !important;
      background-color: transparent !important;
      color: #fff !important;
    }
    .el-menu-item:hover {
      color: #ba49b5  !important;
    }
  }
  .navWrapper {
    width: 100%;
    position: fixed;
    top: 0;
    height: 80px;
    // vertical-align:;
    #menu {
      height: unset;
    }
    .logo-item {
      display: none;
    }
    .first-item {
      margin-left: 40%;
    }
    .last-item {
      position: absolute;
      right: 25px;
    }
    .el-menu-item {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  #logo.el-menu-item.is-active {
    border-bottom-color: transparent;
  }

  #toggle {
    position: absolute;
    right: 20px;
    top: 14px;
    z-index: 999;
    width: 40px;
    height: 40px;
    cursor: pointer;
    float: right;
    transition: all .3s ease-out;
    visibility: hidden;
    opacity: 0;
  }

  #toggle .span {
    border-radius: 10px;
    background: #fff;
    transition: all 0.3s ease-out;
    backface-visibility: hidden;
  }

  #top.span.active {
    transform: rotate(45deg) translateX(3px) translateY(5px);
  }

  #middle.span.active {
    opacity: 0;
  }

  #bottom.span.active {
    transform: rotate(-45deg) translateX(8px) translateY(-10px);
  }

    #menu.scrolled {
    background: linear-gradient(180deg, #0F0F2A 23.08%,  rgba(0, 0, 0, 0) 100%);
  }

  @media only screen and (max-width: 768px) {
    #menu {
      background: linear-gradient(116.82deg, #080C27 4.69%, #2B153B 100%);
      // height: calc(var(--menu-height) - var(--wix-ads-height)) !important;
    }

    .el-menu-item.logo-item {
      display: block;
      width: 140px !important;
      margin-left: auto !important;  
      margin-right: auto !important;  
      margin-top: 60px !important;
      margin-bottom: 60px !important;
    }
    .el-menu-item {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .logo-header {
      padding: 0;
    }

    .first-item {
      margin-left: 0 !important;
    }

    .last-item {
      position: unset !important;
      right: unset !important;
    }

    #toggle {
      visibility: visible;
      opacity: 1;
      margin-top: 6px;
    }

    #toggle .span {
      height: 4px;
      margin: 5px 0;
      transition: all .3s ease-out;
      backface-visibility: visible;
      visibility: visible;
      opacity: 1;
    }

    #menu .el-menu-item {
      display: none;
    }

    #menu.active {
      // margin: 70px 0;
      visibility: visible;
      opacity: 0.98;
      transition: all .5s ease-out;

      .el-menu--horizontal .el-menu-item {
        text-align: center;
        float: none;
        display: block;
        height: 100%;
        width: 100%;
        // border-top: 1px solid #EAEAEB;
        font-size: 18px;
        font-size: 14px;
      }
    }
  }
</style>