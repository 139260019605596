<template>
  <div
    class="description_wrapper"
    v-loading.fullscreen.lock="!lastReady"
    element-loading-text=""
    element-loading-spinner="loading-gif"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <h2>
      <a :href="url" target="_blank">{{ title }}</a>
    </h2>
    <!-- <p>{{ description }}</p> -->
    <div class="character_images">
      <div class="character_image1">
        <img :src="img1" :style="adjustment_character_image1" />
      </div>
      <div class="character_image2">
        <img :src="img2" :style="adjustment_character_image2" />
      </div>
      <div class="character_image3">
        <img :src="img3" :style="adjustment_character_image3" />
      </div>
      <div class="character_image4">
        <img
          :src="img4"
          :style="adjustment_character_image4"
          @load="lastReady = true"
        />
      </div>
    </div>
    <!-- 暫時隱藏按鈕 -->
    <!-- <el-button class="button btn-mobile">
        <a :href="url" target="_blank">  {{ $t("common.headToGumroad") }}</a>
    </el-button> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      adjustment_character_image1: "",
      adjustment_character_image2: "",
      adjustment_character_image3: "",
      adjustment_character_image4: "",
      lastReady: false,
    };
  },
  props: [
    "title",
    "description",
    "adjustment",
    "img1",
    "img2",
    "img3",
    "img4",
    "url",
  ],
  mounted() {
    this.applyAdjustment();
  },
  methods: {
    applyAdjustment() {
      // TODO: apply mobile view
      this.adjustment_character_image1 = "";
      this.adjustment_character_image2 = "";
      this.adjustment_character_image3 = "";
      this.adjustment_character_image4 = "";
      if (this.adjustment) {
        Object.keys(this.adjustment).forEach((image) => {
          var css = "";
          Object.keys(this.adjustment[image]).forEach((style) => {
            css += `${style}: ${this.adjustment[image][style]};`;
          });
          this[`adjustment_${image}`] = css;
        });
      }
    },
  },
  watch: {
    title(n, p) {
      this.lastReady = false
    }
  },
};
</script>
<style lang="scss" scoped>
//element-ui

.description_wrapper {
  padding: 0 40px;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  & > h2 {
    color: #fff;
    text-align: center;
    font-size: 36px;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }
  @media (max-width: 600px) {
    padding: 0 16px;
  }
}
.character_images {
  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "img1 img2 img4" "img1 img3 img4";
  gap: 20px;
  height: 50vw;
  max-height: calc(14px * 50);
  position: relative;
  margin-top: 20px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: none;
    gap: 0px;
  }
}
.character_image1,
.character_image2,
.character_image3,
.character_image4 {
  @media (max-width: 600px) {
    width: 100%;
    /* height: 38vw; */
    margin-top: 20px;
  }
  & > img {
    width: 100%;
    max-height: 700px;
    object-fit: cover;
  }
}
.character_image1 {
  grid-area: img1;
}
.character_image2 {
  grid-area: img2;
  img {
    height: 338px;
  }
}
.character_image3 {
  grid-area: img3;
  img {
    height: 339px;
  }
}
.character_image4 {
  grid-area: img4;
}
.character_description {
  align-items: flex-start;
  @media (max-width: 600px) {
    order: -1;
    margin-left: 0;
  }
  margin-left: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  & > * + * {
    margin-top: 20px;
  }
  & > h2 {
    @media (max-width: 600px) {
      font-size: 24px;
    }
    font-size: 48px;
    font-weight: 500;
  }
  & > hr {
    margin-left: 0;
    width: 100%;
    border: 0;
    height: 3px;
    background-image: linear-gradient(to right, #225cc7, #ba49b5);
    @media (max-width: 600px) {
      width: 80%;
    }
  }
  & > p {
    white-space: pre-line;
    @media (max-width: 600px) {
      font-size: 12px;
    }
    font-size: 16px;
    line-height: 140%;
  }
}

.button {
    @media (max-width: 600px) {
        font-size: 14px;
        width: 100%;
    }
    font-size: 16px;
    color: #ffffff;
    background: linear-gradient(85.56deg, #225cc7 7.75%, #ba49b5 91.09%);
    border-radius: 5px;
    border: none;
    padding: 1.1em 3.2em;
    margin-bottom: 20px;
}

.btn-mobile {
    margin-top: 20px;
    flex: 0 0 100%;
    // @media (min-width: 600px) {
    //     display: none;
    // }
    padding: 0.8em 1.2em;
}

</style>
