import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { locale as en } from './en'
import { locale as zh } from './zh'

Vue.use(VueI18n)
let messages = {}
messages = { ...messages, en, zh }
const lang = localStorage.getItem('language') || 'en'
const i18n = new VueI18n({
  locale: lang,
  messages,
})
export default i18n