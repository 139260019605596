<template>
    <div class="main">
        <CharactersList
            :images="imgList"
            :current-select="selectedCharater"
            :handle-select="handleSelect"
        />
        <CharactersListMobile
            :images="imgList"
            :current-select="selectedCharater"
            :handle-select="handleSelect"
        />
        <CharacterDescription
            ref="characterDescription"
            :img1="require(`../../assets/img/${selectedCharater.id}/1.jpg`)"
            :img2="require(`../../assets/img/${selectedCharater.id}/2.jpg`)"
            :img3="require(`../../assets/img/${selectedCharater.id}/3.jpg`)"
            :img4="require(`../../assets/img/${selectedCharater.id}/4.jpg`)"
            :title="selectedCharater.title"
            :description="selectedCharater.desc"
            :adjustment="selectedCharater.adjustment"
            :url="selectedCharater.url"
        />
    </div>
</template>
<script>
import jsonList from '../../assets/json/characterInfo.json';

import CharactersList from './CharactersList.vue';
import CharacterDescription from './CharacterDescription.vue';
import CharactersListMobile from './CharactersListMobile.vue';

export default {
    data() {
        return {
            imgList: [],
            selectedCharater: {},
        };
    },
    components: {
        CharactersList: CharactersList,
        CharacterDescription: CharacterDescription,
        CharactersListMobile: CharactersListMobile,
    },
    created() {
        this.initCharaterList();
    },
    methods: {
        initCharaterList() {
            // the id will get where the images are
            /* structer
         {
            "id": 0,
            "title":"Black Cat",
            "desc": "Black cat.....",
            "url": "https://tangmonster.gumroad.com/"
        },
        */
            // set image list, the sequence of them are important so don't change it, it present the id
            this.imgList = jsonList.map((character) => {
                return require(`../../assets/img/${character.id}/main.jpg`);
            });
            // take first charater from the json list
            const firstCharater = jsonList[0];
            this.selectedCharater = firstCharater;
        },
        handleSelect(index) {
            this.selectedCharater = jsonList[index];
            this.$nextTick(() => {
                this.$refs.characterDescription.applyAdjustment()
            })
        },
    },
};
</script>
<style lang="scss" scoped>
//element-ui
.main {
    min-height: 100vh;
    padding-top: 75px;

    @media (max-width: 600px) {
        padding-top: 100px;
    }
}
.main_img_size {
    width: 100%;
}
</style>
