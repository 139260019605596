import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { isEmpty } from './utils/index'
import i18n from './lang/index'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faMagnifyingGlass, faRightFromBracket, faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlus, faTwitter, faInstagram, faFacebook, faPatreon, faTwitch, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCoffee, faGooglePlus, faMagnifyingGlass, faRightFromBracket, faCartShopping,
  faTwitter, faInstagram, faFacebook, faPatreon,
  faTwitch, faYoutube,
  faTiktok
);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.prototype.$http = axios;

router.afterEach((to) => {
  // 无论你跳转至哪个页面,只要没登录,回到登录页面
  let isLogin = store.state.login
  let isAdminLogin = store.state.adminLogin;
  let isNeedToReg = store.state.register
  // if (to.name !== 'Login') //未登录状态
  // {
    //   // 跳转至登录页面
    //   //判断是否登陆而且是否需要注册
    //   if (!isLogin && !isNeedToReg && !isAdminLogin) {
      //     router.replace({
        //       name: 'Login'
        //     })
        //   }
        // }
  if (isEmpty(to.name)) {
    router.push({
      path: '/home/main'
    })
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

if (module.hot) {
  module.hot.accept(['./lang/en', './lang/zh'], function () {
    i18n.setLocaleMessage('en', require('./lang/en').default)
    i18n.setLocaleMessage('zh', require('./lang/zh').default)
    // Or the following hot updates via $i18n property
    // app.$i18n.setLocaleMessage('en', require('./en').default)
    // app.$i18n.setLocaleMessage('ja', require('./ja').default)
  })
}
