<template>
    <div class="cos_img_list">
        <div class="cos_images">
            <div class="cos_image1">
                <img :src="cosImages[0]" />
            </div>
            <div class="cos_image2">
                <img :src="cosImages[1]" />
            </div>
            <div class="cos_image3">
                <img :src="cosImages[2]" />
            </div>
            <div class="cos_image4">
                <img :src="cosImages[3]" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: ['cosImages'],
};
</script>
<style lang="scss" scoped>
//element-ui

.cos_img_list {
    padding: 0 40px;
    max-width: 1400px;
    @media (max-width: 600px) {
        padding: 0 16px;
    }

    display: flex;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
}
.cos_images {
    display: grid;
    grid-template-columns: 3fr 4fr 3fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'img1 img2 img4' 'img1 img3 img4';
    gap: 20px;

    max-height: calc(14px * 50);
    position: relative;
    margin-top: 20px;
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        height: auto;
        max-height: none;
        gap: 5px;
    }
}

.cos_image1,
.cos_image2,
.cos_image3,
.cos_image4 {
    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.cos_image1 {
    grid-area: img1;
    img {    
        object-position: 20%;
        @media (max-width: 600px) {
            object-position: unset;
        }
    }
}
.cos_image2 {
    grid-area: img2;
    img {
        height: 338px;
         @media (max-width: 600px) {
            height: unset;
         }
    }
}
.cos_image3 {
    grid-area: img3;
    img {
         height: 339px;
         @media (max-width: 600px) {
            height: unset;
         }
    }
}
.cos_image4 {
    grid-area: img4;
    @media (max-width: 600px) {
        display: none;
    }
}
</style>
