<template>
    <div
        class="main_img_frame"
        v-loading.fullscreen.lock="!isLoaded"
        element-loading-text=""
        element-loading-spinner="loading-gif"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <img class="main_img_size" :src="mainImg" @load="isLoaded = true" />

        <!-- 照片集> -->
        <ImageList v-bind:cosImages="cosImages" />
        <div class="product_set" v-for="item in itemJsonList" :key="item.id">
            <ProductDescription
                :img="require(`../../assets/img/items/${item.name}/front.jpg`)"
                :title="item.title"
                :url="item.url"
                :description="item.description"
                :isAvailable="item.availableInShop && $store.state.isTaiwan"
            />
        </div>
        <!-- <router-view></router-view> -->
    </div>
</template>
<script>
import main_img from '../../assets/img/main_img.jpg';
import cos_img_1 from '../../assets/img/DSC_0212.jpg';
import cos_img_2 from '../../assets/img/KZS_4819.jpg';
import cos_img_3 from '../../assets/img/KZS_4707.jpg';
import cos_img_4 from '../../assets/img/1/1.jpg';
import loadingImg from '../../assets/img/loading.gif';

import itemJsonList from '../../assets/json/item.json';

import ImageList from './ImageList.vue';
import ProductDescription from './ProductDescription.vue';
export default {
    data() {
        return {
            mainImg: main_img,
            itemJsonList: itemJsonList,
            loadingImg: loadingImg,
            cosImages: [cos_img_1, cos_img_2, cos_img_3, cos_img_4],
            hotdata: [],
            isLoaded: false
        };
    },
    created() {
    },
    methods: {
        initItemList() {
            // the id will get where the items are
            /* structer
        {
            "id": 0,
            "title":"《戎犬鏘鏘》- 五郎",
            "name": "goro",
            "description": "《戎犬鏘鏘》-五郎/36P/B5 (收錄2組拍攝風格) \r 圖片僅限參考為主，商品實際顏色請參考影片 https://fb.watch/hF2nC_1xEw/",
            "url": "https://myship.7-11.com.tw/general/detail/GM2212263133357",
            "availableInShop": true
        }
        */
            // set image list, the sequence of them are important so don't change it, it present the id
            // this.imgList = jsonList.map((character) => {
            //     return require(`../../assets/img/${character.id}/main.jpg`);
            // });
            // take first charater from the json list
            // const firstCharater = jsonList[0];
            // this.selectedCharater = firstCharater;
        },
        onImgLoad() {
            this.isLoaded = true
        }
    },
    mounted() {
        this.$nextTick(() => {
            //$refs绑定元素
            if (!this.scroll) {
                // this.scroll = new BScroll(this.$refs.product, {
                //   //开启点击事件 默认为false
                //   click: true,
                //    scrollY: true,
                // // eventPassthrough: 'vertical'
                // });
                // console.log(this.scroll)
            } else if (!this.$refs.product) {
                return;
            } else {
                this.scroll.refresh();
            }
        });
    },
    components: {
        ImageList: ImageList,
        ProductDescription: ProductDescription,
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
//element-ui
.main_img_frame {
}
.product_set {
    margin-top: 20px;
    padding-bottom: 20px;
}
.main_img_size {
    width: 100%;
}
</style>
