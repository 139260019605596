export const locale = {
    common: {
        title: 'Tang Tang Monster',
        home: '首頁',
        about: '關於 Tang Tang',
        character: '照片組',
        socailMedia: '社交媒體',
        inDev: " (開發中)",
        toTheShop: "前往商店",
        notAvailable: '現在無法購買',
        headToGumroad: '前往Gumroad查看'
      },
  }