import * as string_lib from './string'
// import * as datahub_type from '../api/datahub_data.js'

export const titleCase = string_lib.titleCase
export const titleCaseEachWord = string_lib.titleCaseEachWord

export function isDevMode() {
  var flg = process.env.NODE_ENV === 'development'
  return flg
}

export function isTraceMode() {
  var flg = window.__traceMode || false
  return flg
}


export function isEmpty(val) {
  if (isFunction(val)) {
    return false
  } else if (val === undefined || val === null) {
    return true
  } else if (typeof val === 'string' && val.trim().length === 0) {
    return true
  } else if (val instanceof Date) {
    return false
  } else if (val instanceof Array && val.length === 0) {
    return true
  } else if (val instanceof Object && Object.keys(val).length === 0) {
    return true
  }
  return false
}

export function checkEmpty(val, defalutValue) {
  return isEmpty(val) ? defalutValue : val
}

export function isString(value) {
  if (typeof value === 'string' || value instanceof String) {
    return true
  }
  return false
}

export function isJsonString(value) {
  return isString(value) && (
    (value.startsWith('\{') && value.endsWith('\}')) ||
    (value.startsWith('\[') && value.endsWith('\]'))
  )
}

export function isNumber(value) {
  // 1 or "1" will return true.
  return !isNaN(parseFloat(value)) && isFinite(value)
}

export function isCost(value) {
  // cost should not be zero.
  return isNumber(value) && value !== 0
}

export function isArray(value) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(value) === '[object Array]'
  }
  return Array.isArray(value)
}

export function isObject(value) {
  return typeof value === 'object' && !isArray(value)
}

export function isInteger(value) {
  // 1 or "1" will return true, 1.1 or "1.1" will return false.
  return isNumber(value) && !value.toString().includes('.')
}

export function isBoolean(value) {
  return typeof value === 'boolean'
}

export function isTrue(value) {
  var val = value + ''
  return val === 'true' || val === '1'
}

export function isFunction(value) {
  return typeof value === 'function'
}

export const evals = function(code, var_name = '_tmp_val') {
  window['e' + 'val'](`window['${var_name}'] = ${code}`)
  var val = window[var_name]
  delete window[var_name]
  return val
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} url
 * @returns {string}
 */
export function appendUrlParam(url, key, value) {
  var prefix = url.includes('?') ? '#' : '?'
  var new_url = `${url}${prefix}${key}=${value}`
  return new_url
}

export function appendUrlTimestamp(url, key = '_ts', value = Date.now()) {
  return appendUrlParam(url, key, value)
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
/*
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}
*/

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait = 100, immediate = false) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...orin_args) {
    args = orin_args
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

export function clone(source) {
  if (isEmpty(source)) {
    return source
  }
  return JSON.parse(JSON.stringify(source))
}

export function cloneArguments(source) {
  return Array.prototype.slice.call(source)
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

// --------------------
// Array
// --------------------
export function arrayToObject(source, key, value) {
  const output = {}
  source.forEach(record => {
    const prop = record[key]
    output[prop] = record[value]
  })
  return output
}

export function sortArrayByField(ary, field, isAsc = true) {
  if (isEmpty(field)) {
    ary.sort()
    if (!isAsc) {
      ary.reverse()
    }
  } else {
    if (isFunction(field)) {
      if (isAsc) {
        ary.sort((x, y) => field(x) > field(y) ? 1 : -1)
      } else {
        ary.sort((x, y) => field(x) > field(y) ? -1 : 1)
      }
    } else {
      if (isAsc) {
        ary.sort((x, y) => getFieldValue(x, field) > getFieldValue(y, field) ? 1 : -1)
      } else {
        ary.sort((x, y) => getFieldValue(x, field) > getFieldValue(y, field) ? -1 : 1)
      }
    }
  }
  return ary
}

/**
 * getFieldValue (PS: support nested field)
 * e.g.
 *  obj     = {name: {val: 'admin'}, age: {val: 30}}
 *  field   = 'name.val'
 *  return  = 'admin'
 */
export function getFieldValue(obj, field) {
  if (field.includes('.')) {
    var value = obj
    field.split('.').forEach(f => {
      value = value[f]
    })
    return value
  } else {
    return obj[field]
  }
}

/**
 * reset array to empty (avoid memory leak)
 * @param {*} arr
 */
export function resetArray(arr) {
  if (!isEmpty(arr)) {
    arr.forEach(item => {
      item = undefined
    })
  }
  arr = []
  return arr
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArray(arr) {
  return Array.from(new Set(arr))
}
// eslint-disable-next-line no-extend-native
Array.prototype.unique = function() {
  return uniqueArray(this)
}

export function reduceArray(ary) {
  // fix: Reduce of empty array with no initial value
  return ary && ary.length > 0 ? ary.reduce((prev, curr) => prev + curr) : 0
}

// --------------------
// css
// --------------------

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

export function reflow() {
  window.dispatchEvent(new Event('resize')) // Trigger event to prevent line chart is not responsive.
}
