<template>
    <div ref="cList" class="list">
        <div class="image_wrapper" v-for="(image, index) in images">
            <img :src="image" :class="{ isSelected: currentSelect.id === index }" @click="handleSelect(index)" />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    mounted() {
        this.$refs.cList.addEventListener("wheel", (evt) => {
        evt.preventDefault();
        this.$refs.cList.scrollLeft += evt.deltaY;
    })
    },
    onUnmounted() {
        this.$refs.cList.removeEventListener("wheel")
    },
    props: {
        images: {
            type: Array,
            default: []
        },
        currentSelect: {
            type: Object,
            require: true
        },
        handleSelect: {
            type: Function,
            require: true
        }
    },
};
</script>
<style lang="scss" scoped>
//element-ui
.list {
    display: flex;
    height: 400px;
    width: 98vw;
    margin: 0 auto;
    //  width: min(1400px, 100vw);
    overflow-x: scroll;
    overflow-y: hidden;
    @media (max-width: 600px) {
        display: none;
    }
    &::-webkit-scrollbar {
        display: none;
    }
}
.image_wrapper {
    & > img {
        height: 300px;
        // let height decide
        // width: 200px;
        object-fit: cover;
        cursor: pointer;
        margin-top: 50px;
        transition: all 0.5s;
    }
    & img:hover {
        margin-top: 25px;
        height: 350px;
        // width: 266.7px;
        transition: all 0.5s;
    }
    & img.isSelected {
        margin-top: 0px;
        height: 400px;
        // width: 300px;
        transition: all 0.5s;
    }
}
</style>
