<template>
    <div class="description_wrapper">
        <div
            class="product_description"
            v-bind:class="{ reverse: reverse ? true : false }"
        >
            <div class="product_img">
                <img :src="img" />
            </div>
            <div
                class="description"
                v-bind:class="{ reverseText: reverse ? true : false }"
            >
                <h2>{{ title }}</h2>
                <p>{{ description }}</p>
                <el-button :disabled="!isAvailable" class="button btn-PC">
                    <a v-if="isAvailable" :href="url" target="_blank">  {{ $t("common.toTheShop") }}</a>
                    <span v-else>{{ $t("common.notAvailable") }}</span>
                </el-button>
            </div>
        </div>
        <el-button :disabled="!isAvailable" class="button btn-mobile">
            <a v-if="isAvailable" :href="url" target="_blank">  {{ $t("common.toTheShop") }}</a>
            <span v-else>{{ $t("common.notAvailable") }}</span>
        </el-button>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: ['title', 'description', 'reverse', 'img', 'url', 'isAvailable'],
};
</script>
<style lang="scss" scoped>
//element-ui

.description_wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px;
    @media (max-width: 600px) {
        padding: 0 16px;
    }
}
.product_description {
    // padding: 40px;
    display: flex;
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 20px;
}

.reverse {
    @media (min-width: 600px) {
        flex-direction: row-reverse;
    }
}

.product_img {
    flex-shrink: 0;
    flex-basis: min(40vw, 360px);
    height: min(55vw, 470px);
    & > img {
        width: 100%;
        max-height: 500px;
        object-fit: cover;
    }
}
.description {
    align-items: flex-start;
    @media (max-width: 600px) {
        margin-left: 24px;
    }
    margin-left: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #fff;
    & > * + * {
        margin-top: 20px;
    }
    & > h2 {
        @media (max-width: 600px) {
            font-size: 16px;
        }
        font-size: 48px;
        font-weight: 500;
    }
    & > p {
        white-space: pre-line;
        @media (width > 900px) {
            min-width: 610px;
        }
        @media (max-width: 600px) {
            font-size: 12px;
        }
        font-size: 16px;
        line-height: 140%;
    }
}

.reverseText {
    @media (min-width: 600px) {
        align-items: flex-end;
        margin-left: 0px;
        margin-right: 50px;

        & > * {
            text-align: right;
        }
    }
}

.button {
    @media (max-width: 600px) {
        font-size: 14px;
    }
    font-size: 16px;
    color: #ffffff;
    background: linear-gradient(85.56deg, #225cc7 7.75%, #ba49b5 91.09%);
    border-radius: 5px;
    border: none;
    padding: 1.1em 3.2em;
}
.button.is-disabled {
    color: #582356;
    background: linear-gradient(85.56deg, #728fc5 7.75%, #bd89ba 91.09%);
}
.button.is-disabled:hover {
    color: #582356;
    background: linear-gradient(85.56deg, #728fc5 7.75%, #bd89ba 91.09%);
}

.btn-PC {
    @media (max-width: 600px) {
        display: none;
    }
}

.btn-mobile {
    margin-top: 20px;
    flex: 0 0 100%;
    @media (min-width: 600px) {
        display: none;
    }
    padding: 0.8em 1.2em;
}
</style>
