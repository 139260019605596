<template>
    <div class="">
        <iframe
            src="https://player.twitch.tv/?channel=tangtangmonster7&parent=tangmonster.com"
            height="500"
            width="700"
            allowfullscreen
        >
        </iframe>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    components: {},
    created() {},
    methods: {},
};
</script>
<style lang="scss" scoped>
//element-ui
.main {
    min-height: 100vh;
    padding-top: 75px;

    @media (max-width: 600px) {
        padding-top: 100px;
    }
}
.main_img_size {
    width: 100%;
}
</style>
