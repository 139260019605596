<template>
    <div id="app">
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>
<script>
// import apphead from './components/apphead.vue'
import { mapstate } from 'vuex';
import Footer from './components/Footer.vue';

export default {
    name: 'app',
    components: {
        Footer: Footer,
    },
};
</script>

<style
    lang="scss"
    src="./assets/scss/overall.scss"
    rel="stylesheet/scss"
></style>
