export const locale = {
  common: {
    title: 'Tang Tang Monster',
    home: 'Home',
    about: 'About Tang Tang',
    character: 'Photo Group',
    socailMedia: 'Social Media',
    inDev: ' (In Dev)',
    toTheShop: 'To The Shop',
    notAvailable: 'Not Available For Now',
    headToGumroad: 'Check in Gumroad'
  },
}