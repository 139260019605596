import Vue from 'vue';
import Router from 'vue-router';
// import Home from './views/Home.vue'
import Home from './views/homepage/Home';
import Main from './views/homepage/Main';
import About from './views/homepage/About';
import MygoodsList from './views/MygoodsList';

import Characters from './views/characterspage/Characters';

import SocialMedia from './views/socialmediapage/socialMedia';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/home',
            name: 'Home',
            component: Home,
            children: [
                {
                    path: 'main',
                    name: 'Main',
                    component: Main,
                },
                {
                    path: 'about',
                    name: 'About',
                    component: About,
                },
                {
                    path: 'character',
                    name: 'character',
                    component: Characters,
                },
                {
                    path: 'socialmedia',
                    name: 'socialmedia',
                    component: SocialMedia,
                },

                // {
                //   path: 'item',
                //   name: 'Item',
                //   component: Item
                // },
                // {
                //   path: 'myorderList',
                //   name: 'MyOrderList',
                //   component: MyOrderList
                // }
            ],
        },
    ],
});
