<template>
    <div class="list_mobile">
        <div
            v-for="(image, index) in images"
            class="image_wrapper"
            :class="{ selected: currentSelect.id === index }"
        >
            <img :src="image" @click="handleSelect(index)" />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: {
        images: {
            type: Array,
            default: [],
        },
        currentSelect: {
            type: Object,
            require: true,
        },
        handleSelect: {
            type: Function,
            require: true,
        },
    },
};
</script>
<style lang="scss" scoped>
//element-ui
.list_mobile {
    height: 100px;
    width: 100vw;
    margin: 0 auto;
    overflow-x: scroll;
    display: none;
    @media (max-width: 600px) {
        display: flex;
    }
}

.selected {
    filter: brightness(70%);
}
.image_wrapper {
    transition: filter 0.7s ease;
    box-sizing: border-box;
    height: 80px;
    flex: 0 0 80px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    top: 2px;
    left: 2px;

    border: 3px solid transparent;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>
